$maincolor: #591e6f;
$txtcolor: #1f0a27;
$subtxtcolor: #b0aab2;
$subtxtcolor2: #b2aaaa;
$bgcolor: #f6f5f7;
$myprojectbasecolor: #ece9f0;
$sharedprojectbasecolor: #f8f3ec;
$sharedtxtcolor: #5c544b;
$headingtxtcolor: #aea4b2;

//mine
$mybubblecolor: $maincolor;
$mybubbletxtcolor: #4dc9ec;

//base
$basebubblecolor: white;
$basebubbleelcolor: $subtxtcolor;
$basebubbletxtcolor: $txtcolor;

//pinned
$pinnedbubblecolor: #45c5e9;
$pinnedbubbletxtcolor: white;
$pinnedbubbleelcolor: #cff7ff;

//selected
$selectedbubblecolor: #c5c1c7;
$selectedbubbletxtcolor: $maincolor;
$selectedbubbleelcolor: $maincolor;

//comment
$commentcolor: #e6e8f0;
$commenttxtcolor: #274293;

//shared
$sharedbgcolor: #f7f6f5;
$sharedcommentcolor: #ebeae8;
