@import '../../../../sass/_var.scss';

.user {
  display: flex;
  align-items: center;
  margin: 20px 0;

  &-icon {
    background-color: #ddd;
  }

  &__user-name {
    line-height: 0.7;
  }

  &__last-update {
    color: $subtxtcolor2;
    font-size: 7px;
    font-weight: 300;
    line-height: 0.5;
  }
}
