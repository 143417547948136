@import '../../../../sass/_var.scss';

.upload-photo {
  text-align: center;

  &__preview {
    width: 120px;
    height: 120px;
    display: block;
    border-radius: 50%;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    position: relative;
  }

  &__btn {
    background: #a2a6b3;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 8px;
  }

  #upload {
    width: 1px;
    height: 1px;
    overflow: hidden;
    outline: 0;
  }
}
