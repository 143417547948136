@import '../../../../sass/_var.scss';

.radio {
  display: block;

  input[type='radio'] {
    display: none;

    & + label {
      box-sizing: border-box;
      transition: background-color 0.2s linear;
      position: relative;
      display: block;
      margin: 0 0 0 0;
      padding: 6px 12px 6px 0;
      vertical-align: middle;
      cursor: pointer;

      &:before {
        transition: opacity 0.2s linear;
        position: absolute;
        top: 52%;
        right: 5px;
        display: block;
        margin-top: -5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        content: '';
        opacity: 0;
        z-index: 1;
        background: $maincolor;
      }

      &:after {
        transition: border-color 0.2s linear;
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        margin-top: -10px;
        width: 18px;
        height: 18px;
        border: 2px solid $headingtxtcolor;
        border-radius: 50%;
        content: '';
      }
    }

    &:checked + label {
      &:before {
        opacity: 1;
      }
      &:after {
        border: 2px solid $maincolor;
      }
    }
  }
}
