@import '../../../../sass/_var.scss';

.project-item {
  margin: 0 0 10px;
  display: flex;
  align-items: center;

  &--shared {
    .project-item {
      &__inner {
        background: $sharedprojectbasecolor;

        .project-item {
          &__title {
            color: $sharedtxtcolor;
            svg {
              rect,
              path {
                fill: $sharedtxtcolor;
              }
            }
          }
        }
      }

      // &__remove {
      //   justify-content: center;
      // }
    }
  }

  &__remove {
    flex: 0 0 25px;
    margin: 0 20px 0 0;
    height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > button {
      width: 15px;
      padding: 5px 0;
      box-sizing: content-box;
    }
  }

  &__inner {
    background: $myprojectbasecolor;
    min-height: 115px;
    padding: 15px 25px;
    border-radius: 6px;
    flex: 0 0 100%;
    text-decoration: none;
    color: $txtcolor;
  }

  &__title {
    font-size: 20px;
    min-height: 50px;
    margin: 0 0 5px;

    span {
      width: 16px;
      display: inline-block;
      margin: 0 5px;
    }
  }
  &__summary {
    display: block;
    font-size: 10px;
    color: $subtxtcolor;
  }
  &__date {
    display: block;
    font-size: 10px;
    color: $subtxtcolor;
  }
}
