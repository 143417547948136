@mixin chatbubbleanimation($ms: 0) {
  .chat-bubble {
    &__content {
      transition-delay: (20ms * $ms);
    }
  }
}

.chat-bubble {
  position: relative;
  min-height: 50px;
  margin: 0 0 16px;

  &--has-note {
    width: 315px;
    min-height: 100px;
    margin-bottom: 20px;

    .chat-bubble {
      &__tooltip {
        &--show {
          left: 0;
          // right: auto;
        }
      }

      &__inner {

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          background: url('/images/chat-bubble.svg') no-repeat right top;
        }
        &:before {
          height: 40px;
          top: 10px;
          width: 26px;
        }
        &:after {
          height: 20px;
          bottom: 15px;
          width: 20px;
          background: $commentcolor;
        }
      }
    }
  }

  &--pinned {
    .chat-bubble {
      &__tooltip-item {
        &:nth-child(1) {
          color: $mybubbletxtcolor;
          svg {
            path,
            rect {
              fill: $mybubbletxtcolor;
            }
          }
        }
      }
    }
  }

  &__inner {
    text-align: left;
    word-break: break-word;
    line-height: 1.5;
    position: absolute;
    min-height: 50px;
    @media screen and (min-width: 667px){
      cursor: pointer;
    }

    &--pinned {
      .chat-bubble {
        &__content {
          background: $pinnedbubblecolor !important;
        }

        &__text {
          color: $pinnedbubbletxtcolor !important;
        }

        &__buttons {
          border-left-color: rgba($pinnedbubbleelcolor, 0.2);
        }

        &__button-wrapper {
          &:before,
          &:after {
            background: #cff7ff;
          }
        }
      }
    }

    &--floated {
      z-index: 4;

      &::before {
        display: none !important;
      }
      &::after {
        display: none !important;
      }

      .chat-bubble {
        &__corpus {
          //opacity: 0.2;
        }
      }
    }

    &--selected {
      .chat-bubble {
        &__content {
          background: $selectedbubblecolor !important;
          box-shadow: 0px 0px 10px 0px rgba($maincolor, 0.15);
        }

        &__text {
          color: $selectedbubbletxtcolor !important;
        }

        &__buttons {
          border-left-color: rgba($selectedbubbleelcolor, 0.2);
        }
        &__button-wrapper {
          &:before,
          &:after {
            background: $selectedbubbleelcolor;
          }
        }
      }
    }
  }

  &__content {
    padding: 8px 15px;
    background: white;
    box-shadow: 0px 0px 10px 5px rgba($maincolor, 0.02);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    border-radius: 6px;
    position: relative;
    z-index: 2;
    //opacity: 0;
    //transform: scale(0.5);
    //transition: opacity 200ms ease-out, transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.appear-done {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__corpus {
    display: block;
    text-align: right;
    font-size: 14px;
    transform: scale(0.5);
    padding: 3px 0;
    transform-origin: top right;
    color: $subtxtcolor;
  }

  span.Linkify {
    display: block;
  }

  img.thinking {
    width: 45px;
    vertical-align: middle;
  }

  &__text {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;

    span {
      color: $subtxtcolor;
    }
  }

  &__note {
    background: $commentcolor;
    border-radius: 9px;
    color: $commenttxtcolor;
    padding: 13px 16px;
    width: 315px;
    margin: -10px 0 0;
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 1;
    text-align: left;
    word-break: break-word;
    font-size: 13px;
    font-weight: 300;
  }

  &__tooltip {
    background: white;
    min-height: 60px;
    border-radius: 6px;
    width: 152px;
    padding: 10px 12px;
    right: 5000px;
    z-index: 1;
    position: absolute;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-20px);
    transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1);
    top: 66px;

    &--show {
      transform: translateY(0);
      right: 0;
      z-index: 4;
      display: flex;
    }

    &--note {
      transform: scale(15);
      transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 100ms linear 400ms;

      .chat-bubble {
        &__tooltip-item {
          opacity: 0;
        }
      }
    }
  }

  &__tooltip-item {
    flex: 0 0 35px;
    margin: 5px 0;
    svg,
    img {
      width: 25px;
      margin: 0 5px;
    }
    span {
      font-size: 10px;
      display: block;
      text-align: center;
      transform: scale(0.9);
      &.phrase {
        letter-spacing: -0.2em;
      }
    }
  }

  &__error {
    display: inline-block;
    text-align: center;
    margin-left: 15px;
    vertical-align: middle;
    padding-top: 4px;
  }
  &__error-icon {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #7d7d7d;
    line-height: 14px;
    margin: 0 auto;
    i {
      color: white;
      font-size: 10px;
    }
  }
  &__error-message {
    color: #7d7d7d;
    font-size: 10px;
    line-height: 15px;
  }
  &__buttons {
    display: inline-block;
    width: auto;
    border-left: 1px solid rgba($basebubbleelcolor, 0.2);
    height: 24px;
    margin-left: 15px;
    margin-right: -15px;
  }

  &__button-wrapper {
    display: block;
    width: 34px;
    height: 24px;
    text-align: center;
    line-height: 1.7em;
    position: relative;
    padding: 5px 10px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background: #c5c1c7;
      top: 11px;
      left: 11px;
    }
    &:before {
      width: 10px;
      height: 2px;
      transform: rotate(90deg);
    }
    &:after {
      width: 10px;
      height: 2px;
    }
  }

  &__source {
    color: #aaa;
    font-size: 10px;
  }

  &__copied-user {
    margin: 5px 0;
    line-height: 30px;
    height: 30px;

    .user-icon {
      margin: 0 7px 0 0;
      transform: translateY(-2px);
    }
  }
}

.dialog {
  &.you {
    .chat-bubble {
      min-width: 152px;
      &--has-note {
        .chat-bubble {
          &__inner {
            &:before {
              right: -16px;
            }
            &:after {
              left: 0;
            }
          }
        }
      }
      &__inner {
        font-size: 17px;
      }

      &__content {
        width: 152px;
      }

      &__note {
        border-top-left-radius: 0;
      }

      &:nth-child(1) {
        @include chatbubbleanimation(0);
      }
      &:nth-child(2) {
        @include chatbubbleanimation(1);
      }
      &:nth-child(3) {
        @include chatbubbleanimation(2);
      }
      &:nth-child(4) {
        @include chatbubbleanimation(3);
      }
      &:nth-child(5) {
        @include chatbubbleanimation(4);
      }
      &:nth-child(6) {
        @include chatbubbleanimation(5);
      }
      &:nth-child(7) {
        @include chatbubbleanimation(6);
      }
      &:nth-child(8) {
        @include chatbubbleanimation(7);
      }
      &:nth-child(9) {
        @include chatbubbleanimation(8);
      }
      &:nth-child(10) {
        @include chatbubbleanimation(9);
      }
    }
  }

  &.me {
    .chat-bubble {
      /*
      opacity: 0;
      transform: translateY(20px);
      transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &--appear {
        opacity: 1;
        transform: translateY(0);
      }
      */
      &--has-note {
        .chat-bubble {
          &__tooltip {
            left: auto;
            // right: 5000px;
          }
          &__inner {
            &:before {
              left: -16px;
              transform: scale(-1, 1);
            }
            &:after {
              right: 0;
              bottom: -5px;
            }
          }
        }
      }

      &__inner {
        position: relative;
        display: inline-flex;
      }

      &__content {
        background: $mybubblecolor;
      }

      &__text {
        color: $mybubbletxtcolor;
      }

      &__note {
        border-top-right-radius: 0;
      }

      a {
        color: $mybubbletxtcolor;
      }
    }
  }

  &.queue {
    .chat-bubble {
      &--has-note {
        .chat-bubble {
          &__tooltip {
            left: auto;
            right: 5000px;
          }
          &__inner {
            &:before {
              left: -16px;
              transform: scale(-1, 1);
            }
            &:after {
              right: 0;
              bottom: -5px;
            }
          }
        }
      }

      &__inner {
        position: relative;
        display: inline-flex;
      }

      &__content {
        background: $selectedbubblecolor !important;
      }

      &__text {
        color: $selectedbubbletxtcolor;
      }

      &__button-wrapper {
        &:before,
        &:after {
          background: $maincolor;
        }
      }

      a {
        color: $selectedbubbletxtcolor;
      }

      &:after {
        background-image: none;
      }
    }
  }
}

.tooltip {
  .chat-bubble {
    position: absolute;
    height: 130px;
    top: 180px;
    left: 30px;
    &__inner {
      width: 152px;
    }
  }
}
