// ==============================================
// Variables
// ==============================================

$dotWidth: 5px;
$dotHeight: 5px;
$dotRadius: $dotWidth/2;

$dotColor: #666;
$dotBgColor: $dotColor;
$dotBeforeColor: $dotColor;
$dotAfterColor: $dotColor;

$dotSpacing: $dotWidth + $dotWidth/1.5;

@import '_mixins.scss';
@import '_dot-flashing.scss';
