@import 'contents';
@import 'chat';
@import 'dialog';
@import 'quick';
@import 'notification';
@import 'footer';
@import 'menu';
@import 'checkbox';
@import 'copiable-text';
@import 'note';
@import 'tool-tip';

#contents {
  background: $bgcolor;
  min-height: 100vh;

  &.shared {
    background: #f7f6f5;

    .dialog {
      .chat-bubble {
        &__inner {
          &::before {
            background-image: url('/images/chat-bubble-shared.svg') !important;
          }
          &::after {
            background: #ebeae8 !important;
          }

          &--selected {
            .chat-bubble {
              &__content {
                background: #d9d8d4 !important;
              }
              &__text {
                color: #595755 !important;
              }
            }
          }
          &--pinned {
            &::after {
              background: #ebeae8 !important;
            }
            .chat-bubble {
              &__content {
                background: #a6a08f !important;
              }
            }
          }
        }
        &__note {
          background: #ebeae8;
          color: #33302b;
        }
      }

      &.me {
        .chat-bubble {
          &__inner {
            .chat-bubble {
              &__content {
                background: #595755;
              }
              &__text {
                color: white;
              }
            }
          }
        }
      }

      &.queue {
        .chat-bubble {
          &__inner {
            .chat-bubble {
              &__content {
                background: #d9d8d4 !important;
              }
              &__text {
                color: #595755;
              }
            }
          }
        }
      }
    }
  }

  &.filtered {
    .dialog.queue {
      display: none;
    }
    .dialog.you:not(.dialog--last) {
      .chat-bubble {
        &:not(.chat-bubble--has-note) {
          display: none;
        }
        &.chat-bubble--pinned {
          display: block !important;
        }
        &.chat-bubble--selected {
          display: block !important;
        }
      }
    }
  }
}
