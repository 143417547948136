.quick-reply {
  max-height: 44px;
  transition: max-height 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  height: auto;

  &.entering {
    max-height: 44px;
  }
  &.entered {
    max-height: 44px;
  }
  &.exiting {
    max-height: 0;
  }
  &.exited {
    max-height: 0;
  }

  ul {
    width: auto;
    white-space: nowrap;
    padding: 8px 0 16px;

    li {
      display: inline-block;
      width: auto;
      margin: 0 7.5px;
      &:first-child {
        margin-left: 15px;
      }

      &:last-child {
        margin-right: 15px;
      }
      a {
        color: $maincolor;
        padding: 0 10px;
        border: 1px solid $maincolor;
        text-decoration: none;
        height: 28px;
        line-height: 28px;
        display: block;
        height: 28px;
        border-radius: 14px;
      }
    }
  }
}
