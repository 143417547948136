.notification {
  background: #7d7d7d;
  padding: 11px;
  flex: 0;

  &__inner {
    color: white;
    font-size: 12px;
    align-items: center;
    text-align: center;
  }
}
