.copiable-text {
  display: flex;
  border-radius: 6px;
  background: #f6f5f7;
  display: flex;
  padding: 0 20px;
  cursor: pointer;
  min-height: 43px;
  line-height: 43px;
  overflow: hidden;

  &--copied {
    .copiable-text {
      &__message {
        display: block;
      }
      &__icon {
        &:before {
          transform: scale(25);
          background: rgba($maincolor, 1);
        }
      }
    }
  }

  &__value {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
  }
  &__message {
    display: none;
    color: $mybubbletxtcolor;
    position: absolute;
    z-index: 1;
  }
  &__icon {
    flex: 0 0 27px;
    position: relative;
    padding: 12px 0 12px 12px;

    &:before {
      content: '';
      background: rgba($maincolor, 0);
      position: absolute;
      top: 10px;
      right: -2px;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1), background-color 300ms ease-out;
      transform: scale(2);
    }
  }

  input[type='checkbox'] {
    display: none;

    & + label {
      box-sizing: border-box;
      transition: background-color 0.2s linear;
      position: relative;
      display: inline-block;
      margin: 0 20px 8px 0;
      padding: 6px 12px 6px 32px;
      vertical-align: middle;
      cursor: pointer;

      &:before {
        transition: opacity 0.2s linear;
        position: absolute;
        top: 52%;
        left: 6px;
        display: block;
        margin-top: -7px;
        width: 5px;
        height: 9px;
        border-right: 2px solid $mybubbletxtcolor;
        border-bottom: 2px solid $mybubbletxtcolor;
        content: '';
        opacity: 0;
        transform: rotate(45deg);
        z-index: 1;
      }

      &:after {
        transition: border-color 0.2s linear;
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        margin-top: -10px;
        width: 18px;
        height: 18px;
        border: 2px solid $maincolor;
        content: '';
      }
    }

    &:checked + label {
      &:before {
        opacity: 1;
      }
      &:after {
        background: $maincolor;
      }
    }
  }
}
