.note {
  padding: 20px 0 0;
  &__title {
    color: $subtxtcolor;
    font-size: 13px;
    margin: 0 0 30px;
    span {
      color: $txtcolor;
      font-size: 32px;
      display: inline-block;
      padding-right: 10px;
    }
  }
  &__editable {
    textarea {
      outline: 0;
      width: 100%;
      resize: none;
      font-size: 19px;
    }
  }

  &__selection {
    &-wrapper {
      margin: 30px 0;

      &--column {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        & > * {
          width: 152.5px;
        }
      }
    }

    width: 100%;
    display: block;
    margin: 0 0 10px;

    &-item {
      display: block;
      width: 100%;
      font-weight: 500;
      background: $bgcolor;
      border-radius: 9px;
      padding: 15px 20px;

      .checkbox {
        margin-bottom: 0 !important;

        label {
          span {
            color: $subtxtcolor;
          }
        }
      }
    }
  }
}
