@import '../../../../sass/_var.scss';

.text-input {
  position: relative;

  input {
    width: 100%;
  }

  &__btn {
    background: #a2a6b3;
    position: absolute;
    right: 0;
    bottom: calc(50% - 8px);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    padding: 4px;
    display: none;

    &--active {
      display: block;
    }
  }
}
