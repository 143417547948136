.loading {
  .loader {
    display: block;
  }
}

$foreground: #000;

.loader,
.loader:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.loader {
  display: none;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 2;
  margin: 0;
  font-size: 10px;
  text-indent: -9999em;
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite;
  stroke: #591e6f;
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
