@import 'components/index';
@import 'pages/index';

html {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}
body {
  overscroll-behavior: none;
  overflow: hidden;
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Avenir', -apple-system, Hiragino Sans, 'ヒラギノ角ゴ ProN W3', Meiryo UI, Meiryo,
    'メイリオ', 'ＭＳ Ｐゴシック', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 0.19px;
  height: 100%;
}

button {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
  font-weight: 600;
}

img,
svg {
  width: 100%;
  height: auto;
  display: block;
}

#app {
  overscroll-behavior: none;
  overflow: hidden;
  height: 100%;
  background: #e5e3e6;
  @media screen and (min-width: 667px){
    text-align: center;
  }
  #react-app {
    overscroll-behavior: none;
    overflow-y: hidden;
    height: 100%;

    & > .wrapper {
      overscroll-behavior: none;
      overflow: hidden;
      height: 100%;
      @media screen and (min-width: 667px){
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
      }
      & > .container {
        overscroll-behavior: none;
        overflow: hidden;
        height: 100%;
        padding: 60px 0 50px;
        @media screen and (min-width: 667px){
          flex: 0 0 375px;
        }
        .timeline-wrapper {
          @media screen and (min-width: 667px){
            height: 100%;
            overflow-y: scroll;
            border-right: 1px solid #979598;
            position: relative;
          }
        }
      }
      .react-swipe-container {
        @media screen and (max-width: 666px){
          overscroll-behavior: none;
          height: 100%;
        }

        & > div {
          height: 100%;
        }
        .container {
          background: $bgcolor;
          @media screen and (max-width: 666px){
            overscroll-behavior: none;
            overflow: hidden;
            height: 100%;
          }
          &.shared {
            background: $sharedbgcolor;
          }

          .timeline-wrapper {
            @media screen and (max-width: 666px){
              overscroll-behavior: none;
              overflow-y: scroll;
              -webkit-overflow-scrolling: touch;
              height: 100%;
            }
            opacity: 0;
            transition: opacity 100ms ease-out;
            &.loaded {
              opacity: 1;
            }
          }
        }
      }
    }

    & > .projects {
      @media screen and (max-width: 666px){
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        height: 100%;
      }
    }
  }
}

.thinking {
  padding: 0 0 30px;
  &__inner {
    width: 65px;
    padding: 8px 30px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    border-radius: 10px;
  }
}
