.user-list {
  display: flex;
  @media screen and (min-width: 667px){
  }

  &__thumb {
    span{
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #ddd;
    }
  }

  &__info {
    padding-left: 10px;
  }

  &__title {
    font-weight: bold;
  }

  &__sub{
    font-size: 10px;
    color: #aaa;
  }

  &__wrapper {
    @media screen and (min-width: 667px){
    }
  }

  &__header {
    @media screen and (min-width: 667px){
    }
  }
}
