// ==============================================
// Mixins
// ==============================================

@mixin dot($width: $dotWidth, $height: $dotHeight, $radius: $dotRadius,$bgColor: $dotBgColor, $color: $dotColor ) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bgColor;
  color: $color;
}
