@import '../../../../sass/_var.scss';
.header {
  height: 60px;
  padding: 10px 20px 0;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  @media screen and (min-width: 667px){
    background: white;
    position: fixed;
  }

  &.shared {
    background: #f7f6f5;
  }

  &__inner {
    display: flex;
    height: 40px;
    align-items: center;
    text-align: center;
    padding: 0;
  }

  &__left {
    flex: 0 0 8vw;
    text-align: left;
    white-space: nowrap;
    @media screen and (min-width: 667px){
      flex: 0 0 120px;
    }
  }

  &__center {
    flex: 1;
    text-align: left;
    position: relative;
    height: 40px;
    @media screen and (min-width: 667px){
      text-align: center;
    }

    label {
      font-size: 16px;
      font-weight: bold;
      display: block;
      //position: absolute;
      //top: 0;
      //left: 0;
      height: 40px;
      line-height: 40px;
      transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
      //width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      transform: translateY(0);
      opacity: 1;

      .scroll-top & {
        @media screen and (max-width: 666px){
          transform: translateY(-10px);
          opacity: 0;
        }
      }
      @media screen and (min-width: 667px){
        font-size: 20px;
        display: inline;
        cursor: pointer;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid #ccc;
          border-right: 2px solid #ccc;
          right: -15px;
          bottom: calc(50%);
          transform: rotate(45deg);
        }
      }
    }
  }

  &__right {
    flex: 0 0 16vw;
    justify-content: space-between;
    text-align: right;
    white-space: nowrap;
    font-size: 15px;
    display: flex;
    @media screen and (min-width: 667px){
      flex: 0 0 120px;
    }
  }

  &__btn-note {
    width: 30px;
    height: auto;
    display: block;
    padding: 0px;
    box-sizing: content-box;

    svg,
    img {
      width: 30px;
      height: auto;
      vertical-align: middle;
    }

    &--active {
      svg {
        .el {
          fill: $mybubbletxtcolor;
        }
      }
    }
  }

  &__btn {
    width: 20px;
    height: auto;
    display: block;
    padding: 10px;
    box-sizing: content-box;

    img {
      width: 20px;
      height: auto;
      vertical-align: middle;
    }
  }
}
