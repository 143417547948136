.sso {
  text-align: center;
  background: white;
  display: flex;
  padding: 30px;
  min-height: 100%;
  flex-direction: column;

  input[type='text'],
  input[type='password'] {
    font-size: 19px;
  }

  &__title {
    margin: 100px auto 60px;
    text-align: right;
    width: 115px;
    flex: 1;

    p {
      font-size: 45px;
      font-weight: 600;
      line-height: 40px;
    }
    span {
      font-size: 9px;
      font-weight: 600;
      line-height: 9px;
    }
  }

  &__error {
    color: #f84e45;
    font-size: 11.5px;
    text-align: left;
    visibility: hidden;
    &--show {
      visibility: visible;
    }
  }

  &__submit {
    background: $maincolor;
    color: $mybubbletxtcolor;
    display: block;
    line-height: 45px;
    height: 45px;
    width: 100%;
    font-weight: 600;
    flex: 0;
    text-decoration: none;
    &--disabled {
      background: #f6f5f7;
      color: #bdbabf;
    }

    &[disabled] {
      background: #f6f5f7;
      color: #bdbabf;
    }
  }
}
