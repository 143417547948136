@import '../../../../sass/_var.scss';

.divider {
  display: block;
  width: 100%;
  border: 0;
  margin: 10px 0;
  display: flex;
  align-items: center;

  & > span {
    border-top: 2px dotted $subtxtcolor;
    height: 0;
    flex: 1;
  }

  &__inner {
    margin: 0 12px;
    flex: 0 0 auto !important;
    border: none !important;
    height: 40px !important;
    line-height: 40px;
    font-weight: bold;

    img {
      width: 0.9em;
      height: 0.9em;
      display: inline-block;
    }
  }

  &__type {
    line-height: 1;
  }

  &__arrow {
    width: 12px;
    height: 14px;
    transform: translateY(2px);
    display: inline-block;
    background-size: contain;
    margin: 0 0 0 5px;
  }
}
