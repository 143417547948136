.tooltip {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  transition: opacity 100ms ease-in-out;
  opacity: 0;

  &-enter-done {
    opacity: 1;
  }

  &__inner {
    width: 100%;
    height: 100%;
    background: rgba(black, 0.6);
    padding: 30px;
  }
}
