.checkbox {
  display: block;
  margin: 0 0 8px;

  &.layout-right {
    input + label {
      margin: 0 !important;
      padding: 6px 12px 6px 0 !important;
      &:before {
        left: unset !important;
        right: 6px;
      }

      &:after {
        left: unset !important;
        right: 0px;
      }
    }
  }

  input[type='checkbox'] {
    display: none;

    & + label {
      box-sizing: border-box;
      transition: background-color 0.2s linear;
      position: relative;
      display: block;
      margin: 0 20px 0 0;
      padding: 6px 12px 6px 32px;
      vertical-align: middle;
      cursor: pointer;

      &:before {
        transition: opacity 0.2s linear;
        position: absolute;
        top: 52%;
        left: 6px;
        display: block;
        margin-top: -7px;
        width: 5px;
        height: 9px;
        border-right: 2px solid $mybubbletxtcolor;
        border-bottom: 2px solid $mybubbletxtcolor;
        content: '';
        opacity: 0;
        transform: rotate(45deg);
        z-index: 1;
      }

      &:after {
        transition: border-color 0.2s linear;
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        margin-top: -10px;
        width: 18px;
        height: 18px;
        border: 2px solid $headingtxtcolor;
        border-radius: 4px;
        content: '';
      }
    }

    &:checked + label {
      &:before {
        opacity: 1;
      }
      &:after {
        background: $maincolor;
        border: 2px solid $maincolor;
      }
    }
  }
}
