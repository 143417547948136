@import '../../../../sass/_var.scss';

.timeline-note {
  flex: 0 0 100%;
  margin: 10px 0;

  &__info {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;

    .user-icon {
      margin: 0 5px 0 0;
      border: 5px solid $bgcolor;
      transform: translateY(6px);
    }
  }

  &__name {
    font-size: 16px;
    font-weight: bold;
  }

  &__date {
    flex: 1;
    text-align: right;
    font-size: 10px;
    color: $subtxtcolor;
  }

  &__inner {
    background: $commentcolor;
    color: $commenttxtcolor;
    border-radius: 9px;
    padding: 20px;
    text-align: left;

    & > span.Linkify {
      display: block;

      & > span {
        display: block;
        //height: 1.2em;
      }
    }
  }
}

#contents.shared {
  .timeline-note {
    &__info {
      .user-icon {
        border: 5px solid $sharedbgcolor;
      }
    }

    &__inner {
      background: $sharedcommentcolor;
      color: $sharedtxtcolor;
    }
  }
}
