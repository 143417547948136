.dialog {
  padding: 0;
  margin-bottom: 15px;
  text-align: center;
  width: 100%;

  &__cover {
    background: rgba(#000000, 0.4);
    transition: opacity 300ms ease-out;
    width: 100vw;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;

    &.enter-done {
      opacity: 1;
    }

    &-inner {
      display: flex;
      flex-direction: column;
    }

    &-thumb {
      margin: 10px 0;
      text-align: center;
      span {
        background-color: #ddd;
        background-size: cover;
        display: block;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin: auto;
      }
    }

    &-username {
      font-size: 32px;
      font-weight: bold;
      color: white;
      margin: 10px 0;
      text-align: center;
      line-height: 1;
    }

    &-indicator {
      text-align: center;

      &-item {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #595755;
        border-radius: 50%;
        margin: 0 4px;

        &--active {
          background: white;
        }
      }
    }
  }

  &__header {
    font-size: 32px;
    font-weight: bold;
    white-space: normal;
    margin-bottom: 15px;
  }

  &__description {
    font-weight: 300;
    margin-bottom: 30px;
    font-size: 13px;
    display: flex;
    align-items: flex-end;

    p {
      min-height: 1.2em;
    }

    &--toggle {
      .dialog {
        &__description {
          &-inner {
            max-height: initial;
          }
          &-arrow {
            .fa {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    a {
      color: $mybubbletxtcolor;
    }

    &-inner {
      flex: 1;
      line-height: 1.5em;
      max-height: 2.8em;
      overflow: hidden;
    }
  }

  .date {
    display: block;
    text-align: right;
    font-size: 10px;
    padding: 5px 0;
    color: $subtxtcolor;
  }

  &.cards {
    padding: 0;
  }

  &.you {
    text-align: left;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    vertical-align: top;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }

  &.me {
  }
  &.queue {
  }

  &.me,
  &.queue {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }

  &__more {
    color: $subtxtcolor;
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin: 20px 0;
    cursor: pointer;
  }

  &__note {
    width: 100%;
    &-inner {
      width: 100%;
      text-align: right;
    }

    &-btn {
      margin: 0 0 0 auto;
      width: 40px;
      height: auto;
      display: block;
      padding: 2px;
      box-sizing: content-box;
      background: white;
      border-radius: 15px;
      box-shadow: 0px 0px 10px 0px rgba($maincolor, 0.15);

      svg,
      img {
        width: 30px;
        height: auto;
        margin: 0 auto;
        //vertical-align: middle;
        .el {
          fill: $mybubbletxtcolor;
        }
      }
    }

    &--active {
      svg {
        .el {
          fill: $mybubbletxtcolor;
        }
      }
    }
  }
}
